<template>
  <div class="detail--information">
    <el-table :data="information" :show-header="false">
      <el-table-column>
        <template slot-scope="scope">
          <div class="action--row" v-if="scope.row.action">
            <span class="title font-xsm">{{ scope.row.label }}</span>
            <el-button
              @click="showModal(scope.row.action.modal)"
              type="text"
              v-if="scope.row.action"
            >
              {{ scope.row.action.type }}
            </el-button>
          </div>
          <span v-else class="title font-xsm">{{ scope.row.label }}</span>
          <p v-if="scope.row.text" class="text font-sm">{{ scope.row.text }}</p>
          <p v-else>-</p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "CustomerInformation",
  props: {
    information: {
      type: Array,
      default() {
        return [];
      },
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      showLog: false,
    };
  },
  methods: {
    showModal(modal) {
      if (modal === "gardener_log") {
        this.showLog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail--information {
  .el-table {
    color: #1d352a;

    .title {
      color: #8aa398;
      font-weight: 400;
    }

    .text {
      margin: 4px 4px 4px 0;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action--row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}
</style>
