<template>
  <div>
    <eden-information-card :title="'Preferences'">
      <template slot="content">
        <eden-loader v-if="loading" />
        <el-table v-else :data="preferences" :show-header="false">
          <el-table-column>
            <template slot-scope="scope">
              <div class="title">
                <span>{{ formatText(scope.row.service) }}</span>
                <el-dropdown class="more" @command="command">
                  <span class="el-dropdown-link">
                    <i class="eden-icon-more-horizontal"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <template v-if="scope.row.preference">
                      <el-dropdown-item
                        icon="eden-icon-edit"
                        :command="{ action: 'edit', row: scope.row }"
                        >Edit preference</el-dropdown-item
                      >
                      <el-dropdown-item
                        icon="eden-icon-delete"
                        :command="{ action: 'delete', row: scope.row }"
                        >Delete preference</el-dropdown-item
                      >
                    </template>
                    <template v-else>
                      <el-dropdown-item
                        icon="eden-icon-add-circle"
                        :command="{ action: 'add', row: scope.row }"
                        >Add preference</el-dropdown-item
                      >
                    </template>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="content">
                <p>{{ scope.row.preference || "-" }}</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </eden-information-card>
    <customer-preference-action
      :show.sync="preference.visibility"
      :action="preference.action"
      :preference="preference.data"
      @action="setPreferences"
    />
  </div>
</template>

<script>
import CustomerPreferenceAction from "@/components/Customers/Individual/Preferences/CustomerPreferenceAction";
import preferences from "@/requests/customers/customer/preferences";

export default {
  name: "CustomerProfilePreferences",
  components: { CustomerPreferenceAction },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      preferences: [],
      preference: {
        visibility: false,
        action: "",
        data: {},
      },
    };
  },
  computed: {
    customerId() {
      return parseInt(this.$route.params.id);
    },
  },
  created() {
    if (this.data.length) {
      this.setPreferences();
    }
  },
  methods: {
    async setPreferences() {
      this.loading = true;
      this.preferences = [];
      this.sortArray({ data: this.data }).forEach((service) => {
        this.getPreference(service);
      });
      this.loading = false;
    },
    async getPreference(service) {
      let preference = {
        service,
        user_id: this.customerId,
      };

      try {
        await preferences.get(this.customerId, service).then((response) => {
          const { data } = response.data;
          if (data.length) {
            preference = {
              ...preference,
              ...data[0],
            };
          }
        });
      } catch (error){
        const errorMessage = error.response.data;
        if (errorMessage.errors) {
          const errorKeys = Object.keys(errorMessage.errors);
          this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
        } else {
          this.$message.error(errorMessage.message);
        }
      } finally {
        this.preferences.push(preference);
      }
    },
    command(command) {
      this.preference.action = command.action;
      this.preference.data = command.row;
      this.preference.visibility = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:first-child {
    font-weight: 500;
    color: var(--eden-grey-secondary);
  }
}

.content p {
  color: var(--eden-grey-secondary);
  max-width: 80%;
  word-break: break-word;
  line-height: 1.8;
}
</style>
