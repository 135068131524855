<template>
  <div>
    <eden-overview-card :title="'Subscription'">
      <template v-if="!customerEmployer" slot="subtitle">
        <el-dropdown @command="setView">
          <span class="font-sm text-cursor">
            {{ views[view] }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :class="{ selected: view === 'current' }" command="current">Current</el-dropdown-item>
            <el-dropdown-item v-if="!deletedStatus" disabled :class="{ selected: view === 'next' }"
              command="next">Next</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template slot="action">
        <el-dropdown v-if="plan" @command="setAction">
          <span class="font-base text-cursor text-primary">
            Actions
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <template v-for="(action, i) in actions[view]">
              <el-dropdown-item v-if="allowAccessFor(action.access)" :key="i" :command="action.command">{{ action.label
              }}</el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button v-else-if="!deletedStatus" type="text" @click="setAction('add')"><span
            class="font-base text-bold">Add</span>
        </el-button>
      </template>

      <template slot="content">
        <eden-loader v-if="loading" />
        <div v-else>
          <template v-if="!deletedStatus && plan">
            <el-row type="flex" class="flex-wrap">
              <el-col :span="24">
                <subscription-service v-for="(value, key, index) in subscription[view].plan" :key="index" :service="key"
                  :service-config="value" :services-count="servicesCount[key] && servicesCount[key].services_count
                    " :services-received-count="servicesReceivedCount[key]" />
              </el-col>
            </el-row>
            <el-row type="flex" class="flex-wrap">
              <el-col :span="24">
                <div class="text-right font-base">
                  <span>{{ formatPrice(subscription[view].amount) }}</span>
                </div>
              </el-col>
            </el-row>
          </template>
          <p v-else class="empty-indicator font-sm text-grey-tertiary">
            This customer has no
            {{ view === "current" ? "current" : "pending" }} subscription plan.
          </p>
        </div>
      </template>
    </eden-overview-card>
    <subscription-next-form :show.sync="showSubscriptionNextForm" :subscription="subscriptionForm.data"
      @updated="getSubscription" :menu-type-variation="menuType" />
    <subscription-form :show.sync="subscriptionForm.visibility" :action="subscriptionForm.action" :customer="{
      name: customerName,
      email: customerEmail,
    }" :subscription="subscriptionForm.data" @updated="getSubscription" :menu-type-variation="menuTypeVariation" />
    <subscription :show.sync="showSubscriptionReconfigure" :subscription="subscriptionForm.data"
      @reconfigured="getSubscription" :menu-type-variation="menuType" />
    <subscription-renewal :show.sync="showSubscriptionRenewal" :subscription="subscriptionForm.data"
      @success="getSubscription" :menu-type-variation="menuType" />
    <subscription-cycle-set :show.sync="showSubscriptionCycleSet" :customer-email="customerEmail"
      @success="getSubscription" />
  </div>
</template>

<script>
import SubscriptionService from "@/components/Subscriptions/Subscription/SubscriptionService";
import Subscription from "@/components/Subscriptions/Subscription/Subscription";
import SubscriptionNextForm from "@/components/Subscriptions/Subscription/SubscriptionNextForm";
import SubscriptionForm from "@/components/Subscriptions/Subscription/SubscriptionForm";
import SubscriptionRenewal from "@/components/Subscriptions/Subscription/SubscriptionRenewal";
import SubscriptionCycleSet from "@/components/Subscriptions/Subscription/SubscriptionCycleSet";

import subscription from "@/requests/subscriptions/subscription";

export default {
  name: "CustomerSubscription",
  props: {
    customerName: {
      type: String,
      default: "",
    },
    customerSubscription: {
      type: Object,
      default() {
        return {};
      },
    },
    customerEmail: {
      type: String,
      default: "",
    },
    customerEmployer: {
      type: [String, Number],
      default: null,
    },
    deletedStatus: {
      type: Boolean,
      return: false,
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  components: {
    SubscriptionService,
    Subscription,
    SubscriptionNextForm,
    SubscriptionForm,
    SubscriptionRenewal,
    SubscriptionCycleSet,
  },
  data() {
    return {
      loading: false,
      view: "current",
      views: {
        current: "Current",
        next: "Next",
      },
      subscription: {
        current: {
          plan: {},
          amount: 0,
        },
        next: {
          plan: {},
          amount: 0,
        },
      },
      servicesReceivedCount: {},
      servicesCount: {},
      subscriptionForm: {
        visibility: false,
        action: "edit",
        data: {},
      },
      showSubscriptionRenewal: false,
      showSubscriptionReconfigure: false,
      showSubscriptionNextForm: false,
      showSubscriptionCycleSet: false,
    };
  },
  computed: {
    plan() {
      const plan = this.subscription[this.view];
      console.log(plan.plan && Object.keys(plan.plan).length, "this.view")
      return plan.plan && Object.keys(plan.plan).length;
    },
    actions() {
      let current = this.deletedStatus
        ? [
          {
            label: "View history",
            command: "history",
            access: "all",
          },
        ]
        : [
          {
            label: "Edit",
            command: "edit",
            access: "superadmin",
          },
          {
            label: "Reconfigure",
            command: "reconfigure",
            access: "all",
          },
          {
            label: "Renew",
            command: "renew",
            access: "all",
          },
          {
            label: "Set cycle",
            command: "set-cycle",
            access: ["admin", "gardener", "gardener_pro"],
          },
          {
            label: "View history",
            command: "history",
            access: "all",
          },
        ];
      return {
        current: current,
        next: [
          {
            label: "Configure",
            command: "configure",
            access: "all",
          },
        ],
      };
    },
    menuType() {
      return this.customerSubscription.menu_type;
    },
    menuTypeVariation() {
      if (this.menuType.menu.id === null) {
        return {};
      }
      let menus = this.$store.getters.menu_variations;
      if (menus.length) {
        let userVariation = menus.filter((menu) => {
          if (this.menuType.menu.id === menu.id) {
            return menu;
          }
        });
        if (userVariation.length) {
          return {
            ...this.menuType,
            menuOptions: userVariation,
          };
        }
      }
      return {};
    },
  },
  watch: {
    customerSubscription() {
      this.getSubscription();
    },
  },
  created() {
    this.getSubscription();
  },
  methods: {
    getSubscription() {
      this.loading = true;
      subscription
        .get(this.customerEmail)
        .then((response) => {
          if (response.data.status) {
            let data;
            if (Array.isArray(response.data.data) && response.data.data.length > 1) {
              data = response.data.data.find(item => typeof item.plan === 'object' && Object.keys(item.plan).length > 0);
              if (!data) {
                data = response.data.data[0];
              }
            } else {
              data = response.data.data[0];
            }
            if (!data) {
              this.loading = false;
              return;
            }
            console.log("setting subscripton", data);
            this.subscription.current = {
              plan: data.plan ?? {},
              amount: data.amount,
            };
            this.subscription.next = {
              plan: data.pending_plan ?? {},
              amount: data.pending_amount,
            };
            this.setSubscription(data);
            this.getServicesCount(data.id);
            this.getServicesReceivedCount(data.id);

            this.loading = false;
          } else {
            this.$message.error(response.data.message);
            this.loading = false;
          }
          console.log("checking", this.subscription[this.view]);
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },
    getServicesCount(id) {
      subscription
        .servicesCount(id)
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data;
            this.servicesCount = data.services_count;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getServicesReceivedCount(id) {
      subscription
        .servicesReceived(id)
        .then((response) => {
          if (response.data.status) {
            this.servicesReceivedCount = response.data.data;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setSubscription(data) {
      const {
        created_at,
        updated_at,
        status,
        id,
        name,
        customer_email: email,
        start_date,
        next_charge_date,
        amount,
        plan,
      } = data;

      this.subscriptionForm.data = {
        created_at,
        updated_at,
        status,
        id,
        name: this.formatName(name),
        email: email.toLowerCase(),
        plan,
        services: plan ? Object.keys(plan) : [],
        start_date,
        next_charge_date,
        amount,
        customer_id: this.customerId,
      };
    },
    setView(view) {
      this.view = view;
    },
    setAction(action) {
      switch (action) {
        case "add":
          this.subscriptionForm.action = "add";
          this.subscriptionForm.data = {};
          this.subscriptionForm.visibility = true;
          break;
        case "edit":
          this.subscriptionForm.action = "edit";
          this.subscriptionForm.visibility = true;
          break;
        case "reconfigure":
          this.showSubscriptionReconfigure = true;
          break;
        case "configure":
          this.showSubscriptionNextForm = true;
          break;
        case "renew":
          this.showSubscriptionRenewal = true;
          break;
        case "set-cycle":
          this.showSubscriptionCycleSet = true;
          break;
        case "history":
          this.$router.push({ name: "customers.individual-subscriptions" });
          break;
        default:
          break;
      }
    },
  },
};
</script>
