<template>
  <el-dialog :visible.sync="shouldShow" @close="closeEvent" width="33%">
    <template slot="title">
      <div class="food-selection">
        <p>
          Food Selection Activity for
          <span class="text-bold">{{ customer }}</span>
        </p>
        <span>
          {{ activityDate }} at
          {{ activityTime }}
        </span>
      </div>
    </template>
    <div class="meal-items">
      <div v-for="(meal, i) in activity.items" :key="i" class="meal-item">
        <div class="title">
          <p>{{ formatDate(meal.deliver_to_customer, "dddd do, m, y") }}</p>
          <router-link
            :to="{
              name: 'orders.order',
              params: { id: meal.customer_order_id },
            }"
          >
            {{ meal.customer_order_id }}
          </router-link>
        </div>
        <ul>
          <template v-for="(item, j) in mealItems">
            <li v-if="meal[item]" :key="j">
              {{ formatMealName(meal[item]) }}
            </li>
          </template>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "CustomerFoodSelection",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mealItems: [
        "first_meal_combo",
        "second_meal_combo",
        "extras_meal_combo",
        "extras_two_meal_combo",
        "extras_three_meal_combo",
        "extras_four_meal_combo",
        "extras_five_meal_combo",
        "extras_six_meal_combo",
        "extras_seven_meal_combo",
        "extras_eight_meal_combo",
        "extras_nine_meal_combo",
        "extras_ten_meal_combo",
        "extras_eleven_meal_combo",
        "extras_twelve_meal_combo",
        "extras_thirteen_meal_combo",
        "extras_fourteen_meal_combo",
        "extras_fifteen_meal_combo",
        "extras_sixteen_meal_combo",
        "extras_seventeen_meal_combo",
        "extras_eighteen_meal_combo",
      ],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customer() {
      return (
        (this.activity.items &&
          this.formatName(this.activity.items[0]?.customer_name)) ||
        ""
      );
    },
    activityDate() {
      return this.formatDate(this.activity.date, "dddd do, m, y");
    },
    activityTime() {
      return this.formatTime(this.activity.date, "12h");
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.food-selection {
  p {
    margin-bottom: 5px;
    font-size: 0.875rem;
    color: var(--eden-grey-primary);
  }

  span {
    font-size: 0.825rem;
    color: var(--eden-grey-tertiary);
  }
}
.meal-items {
  max-height: 400px;
  overflow-y: scroll;
}

.meal-item {
  margin-bottom: 20px;
  background: #57645e10;
  color: #57645e;
  margin-top: 10px;
  padding: 12px;
  border-radius: 8px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    p {
      font-size: 0.825rem;
      color: var(--eden-grey-primary);
    }

    a {
      background: #57645e20;
      color: var(--eden-grey-secondary) !important;
      font-size: 0.825rem;
      padding: 6px;
      border-radius: 4px;
      line-height: 1;
    }
  }

  ul {
    width: 80%;

    li {
      margin-bottom: 7px;
      font-size: 0.825rem;
    }
  }
}
</style>
