import axios from "axios";

export default {
  create(payload) {
    return axios.post("customer_orders/create_preference", payload);
  },

  get(id) {
    return axios.get(`orders/${id}`);
  },

  pickup(id, payload) {
    return axios.put(`customer_order/${id}/date`, payload);
  },

  delivery(id, payload) {
    return axios.put(`customer_order/${id}/date`, payload);
  },

  update(payload) {
    const { id, service, orderPayload, spPayload } = payload;

    return axios.all([
      axios.put(`orders/${id}/${service ? service : ""}`, orderPayload),
      axios.post(`orders/${id}/service_provider`, spPayload),
    ]);
  },

  audits(id) {
    return axios.get(`order/${id}/audits`);
  },

  cancel(service, id) {
    return axios.delete(`service_partners/${service}/provisioned_orders/${id}`);
  },

  complete(id, payload) {
    return axios.put(`customer_order/${id}`, payload);
  },

  feedback(id) {
    return axios.get(`feedback/service/ratings/${id}`);
  },

  addressInfoGet(orderId, type) {
    return axios.get(
      `crm/order/delivery/info?order_id=${orderId}&address_type=${type}`
    );
  },

  addressInfoAdd(payload) {
    return axios.post("crm/order/delivery/info", payload);
  },

  addressInfoUpdate(payload) {
    return axios.post("crm/order/delivery/info/update", payload);
  },
  orderPreferences(id) {
    return axios.get(`${id}/preferences`);
  },
  updateOrderPreferences(payload) {
    return axios.post("order/preferences/add", payload);
  },
  problemLogs(orderId) {
    return axios.get(
      `crm/third_party/problem_log/activity?order_id=${orderId}`
    );
  },

  issuesTracker(orderId) {
    return axios.get(
      `crm/issue-tracker?order_id=${orderId}`
    );
  },
};
