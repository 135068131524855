<template>
  <el-drawer
    :title="'Renew Subscription '"
    :visible.sync="shouldShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <el-row type="flex">
            <el-col :span="24">
              <subscription-configuration
                :config="form.plan"
                @set-config="setConfig"
                :menu-type-variation="menuTypeVariation"
              />
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                :label="applyDiscount ? 'Subtotal' : 'Subscription Amount'"
                prop="amount"
              >
                <el-input v-model="form.amount" v-number />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-checkbox
              v-model="applyDiscount"
              label="Apply a pending discount to this payment"
            >
            </el-checkbox>
          </el-row>
          <el-row v-if="applyDiscount" class="mb-2">
            <div class="service-configuration card font-sm">
              <eden-loader v-if="loading" />
              <template v-else-if="pendingDiscount.length">
                <p>Pending discounts attached to this customer</p>
                <el-form-item
                  label="Select at least one of the discounts available below"
                >
                  <el-checkbox-group
                    class="block-check w-100"
                    v-model="discountRedeemed"
                  >
                    <el-checkbox
                      v-for="(value, key, i) in pendingDiscount"
                      :key="i"
                      :label="value"
                    >
                      <span class="is-flex justify-between">
                        <div>
                          {{
                            value.discount.discount_type === "fixed-amount"
                              ? formatPrice(value.discount.discount_value)
                              : value.discount.discount_value + "%"
                          }}
                          off code
                        </div>
                        <div>
                          {{ value.title
                          }}{{
                            value.reward_type === "apple"
                              ? " via Garden"
                              : " code"
                          }}
                        </div>
                      </span>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </template>
              <template v-else>
                There are no pending discounts available for this customer.
              </template>
            </div>
          </el-row>
          <el-row v-if="discountRedeemed.length" type="flex">
            <el-col :span="24">
              <el-form-item label="Subscription Amount" disabled>
                <el-input v-model="discountedAmount" v-number />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="10" class="flex-wrap">
            <el-col :span="24">
              <el-form-item
                label="Start of new subscription"
                prop="start_date"
                :rules="validateField()"
              >
                <el-date-picker
                  v-model="form.start_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="dd MMM, yyyy"
                  :picker-options="{
                    disabledDate: disabledDates,
                  }"
                  prefix-icon="eden-icon--calendar"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-end">
      <el-button type="primary" :loading="renewing" @click="renew"
        >Renew</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import subscription from "@/requests/subscriptions/subscription";
import customer from "@/requests/customers/customer";

export default {
  name: "SubscriptionRenewal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        services: [],
        plan: {},
        amount: null,
        start_date: "",
      },
      renewing: false,
      applyDiscount: false,
      discountRedeemed: [],
      pendingDiscount: [],
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
    location() {
      return this.$store.getters.location;
    },
    discountedAmount() {
      let amount = this.form.amount;
      if (this.discountRedeemed.length) {
        this.discountRedeemed.forEach((item) => {
          if (item.discount.discount_type === "fixed-amount") {
            amount -= item.discount.discount_value;
          } else {
            amount -= (5 / 100) * this.form.amount;
            // amount = -(item.discount.discount_value/100) * this.form.amount
          }
        });
      }
      return amount;
    },
  },
  watch: {
    show() {
      if (this.show) {
        const { email, plan, amount } = this.subscription;
        this.form.email = email;
        this.form.services = Object.keys(plan);
        this.form.plan = plan;
        this.form.amount = amount;
      }
    },
    applyDiscount() {
      if (this.applyDiscount) {
        this.getPendingDiscounts();
      }
    },
  },
  methods: {
    closeEvent() {
      this.applyDiscount = false;
      this.discountRedeemed = [];
      this.shouldShow = false;
    },
    setConfig({ config, amount }) {
      this.form.plan = config;
      this.form.amount = amount;
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin", "admin", "gardener_pro"])) {
        return false;
      }
      return time.getTime() < new Date().getTime();
    },
    getPendingDiscounts() {
      this.loading = true;
      customer
        .pendingDiscounts(this.subscription.customer_id)
        .then((response) => {
          if (response.data.status) {
            this.pendingDiscount = response.data.data;
            this.loading = false;
            // this.$message.success(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    renew() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.renewing = true;
        const formattedDiscounts = this.discountRedeemed.map((item) => {
          return {
            id: item.redeemed_reward_id,
            type: item.reward_type,
          };
        });
        const payload = {
          plan: JSON.stringify(this.form.plan),
          plan_amount: this.form.amount,
          new_onboarding_flow: true,
          start_date: this.form.start_date,
          apply_redeemed_discounts: this.applyDiscount,
          redeemed_discounts: formattedDiscounts,
          customer_email: this.form.email.toLowerCase(),
        };

        console.log(payload);
        subscription
          .renew(payload)
          .then((response) => {
            if (response.data.status) {
              this.renewing = false;
              this.$emit("success");
              this.$message.success(response.data.message);
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.renewing = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
