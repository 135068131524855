<template>
  <div class="wallet-card">
    <div class="wallet-card__information">
      <div class="value font-lg text-bold">
        {{ formatPrice(customer.wallet_balance) }}
      </div>
      <div class="title font-sm">Wallet Balance</div>
    </div>

    <router-link
      class="wallet-card__action font-sm"
      :to="{ name: 'customers.individual.wallet', params: { id: customer.id } }"
      >Open Wallet</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet-card {
  background-color: var(--eden-grey-secondary);
  color: white;
  padding: 25px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__information {
    .title {
      margin-top: 7px;
    }
  }

  &__action {
    color: var(--eden-green-quinary);
    cursor: pointer;
  }
}
</style>
