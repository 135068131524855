export const LAUNDRY_ACTIVITIES = (order) => {
  return {
    sp_assigned: {
      label: "SP assigned",
      status: !!order.is_sp_assigned,
      time: order.is_sp_assigned_at || "-",
    },
    rider_enroute: {
      label: "Rider en route",
      status: !!order.is_rider_enroute,
      time: order.is_rider_enroute_at || "-",
    },
    rider_pickup: {
      label: "Picked up",
      status: !!order.is_rider_pickup,
      time: order.is_rider_pickup_at || "-",
    },
    pickup_rider_delivered: {
      label: "Received",
      status: !!order.is_pickup_rider_delivered,
      time: order.is_pickup_rider_delivered_at || "-",
    },
    pickup_qc: {
      label: "Quality checked",
      status: !!order.is_pickup_qc,
      time: order.is_pickup_qc_at || "-",
    },
    sent_to_sp: {
      label: "Sent to SP",
      status: !!order.is_sent_to_sp,
      time: order.is_sent_to_sp_at || "-",
    },
    received_from_sp: {
      label: "Received from SP",
      status: !!order.is_received_from_sp,
      time: order.is_received_from_sp_at || "-",
    },
    delivery_qc: {
      label: "Quality checked",
      status: !!order.is_delivery_qc,
      time: order.is_delivery_qc_at || "-",
    },
    dispatched: {
      label: "Dispatched",
      status: !!order.is_dispatched,
      time: order.is_dispatched_at || "-",
    },
    delivery_rider_delivered: {
      label: "Delivered",
      status: !!order.is_delivery_rider_delivered,
      time: order.is_delivery_rider_delivered_at || "-",
    },
  };
};
