<template>
  <div>
    <eden-overview-card :title="'Issues Log'">
      <template slot="content">
        <div class="problem-logs">
          <template v-if="logs.length > 0">
            <div class="problem-log" v-for="(log, index) in logs" :key="index">
              <div class="title">
                <div>
                  <p>{{ log.title && formatName(log?.title) }}</p>
                  <span class="text-grey-tertiary font-sm">{{
                    formatDateAndTime(log.created_at, "dddd ddd, m, y")
                  }}</span>
                </div>
                <el-tag :type="setType(log.status)">
                  {{ formatText(log?.status) }}
                </el-tag>
              </div>
              <div class="description">
                <!-- <p>
                  <el-tag type="info">{{
                    log.issue_category === null
                      ? formatToTitleCase(log?.issue_category)
                      : formatToTitleCase(log?.issue_category[0])
                  }}</el-tag>
                </p> -->
                <p>{{ log.description }}</p>
              </div>
              <div class="details">
                <router-link
                  class="text-primary"
                  :to="{
                    name: 'feedback.issues.issue',
                    params: { id: log?.id },
                  }"
                >
                  View issue details</router-link
                >
              </div>
            </div>
          </template>
          <eden-content-empty v-else :text="'No data'" :size="120" />
        </div>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import order from "@/requests/orders/order";
import customerRequest from "@/requests/customers/individual";

export default {
  name: "OrderIssuesLog",
  props: {
    orderId: {
      type: [String, Number],
      default: "",
    },
     customerId: {
      type: [String, Number],
      default: "",
    },
    service: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logs: [],
    };
  },
  mounted() {
    if(this.orderId){    
      return this.getOrderIssuesTracker()
    }
    this.getCustomerIssuesTracker();
  },
  // mounted() {},
  methods: {
    getCustomerIssuesTracker(){
      customerRequest
       .issuesTracker(this.customerId)
        .then((response) => {
          const { status, data } = response.data;
         

          if (status && data.length > 0) {
            this.$emit("logged");
            this.logs = data;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    getOrderIssuesTracker() {
      order
        .issuesTracker(this.orderId)
        .then((response) => {
          const { status, data } = response.data;
         

          if (status && data.length > 0) {
            this.$emit("logged");
            this.logs = data;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.problem-logs {
  min-height: 100px;
}

.problem-log {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f4f2;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;

    > div {
      p {
        color: var(--eden-grey-primary);
        font-weight: 500;
        font-size: 0.925rem;
        margin-bottom: 5px;
      }
      span {
        font-size: 0.875rem;
        color: var(--eden-grey-tertiary);
      }
    }
  }

  .description {
    p {
      color: var(--eden-grey-primary);
      font-size: 0.875rem;
      line-height: 1.6;
      margin-bottom: 6px;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  p {
    font-size: 0.875rem;
  }
}
</style>
