<template>
  <div>
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <div
          v-for="(service, index) in formResponses.services"
          :key="index"
          class="eden-service--summary"
        >
          <div>
            <p>{{ formatText(service) }}</p>
          </div>
          <div v-if="service !== 'meal'">
            <span>Type</span>
            <p>{{ formatToTitleCase(formResponses.plan[service].item) }}</p>
          </div>
          <div>
            <span>Quantity</span>
            <p>{{ formResponses.plan[service].qty }}</p>
          </div>
          <div>
            <span>Amount</span>
            <p>
              {{
                formResponses.complementary
                  ? 0
                  : formatPrice(formResponses.plan[service].amount)
              }}
            </p>
          </div>
        </div>
        <div class="eden-service--summary">
          <div>
            <span>Total</span>
            <p>
              {{ formResponses.complementary ? 0 : formatPrice(amount) }}
            </p>
          </div>
        </div>
      </div>
      <div class="el-drawer--footer is-justify-between">
        <el-button plain @click="back">Back</el-button>
        <el-button type="primary" @click="create" :loading="creating"
          >Create Order(s)</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import customer from "@/requests/customers/customer/actions";

export default {
  name: "CustomerOneTimeOrderSummary",
  props: {
    formResponses: {
      type: Object,
      default() {
        return {};
      },
    },
    customerId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      creating: false,
      amount: 0,
    };
  },
  created() {
    this.calculatePrice();
  },
  methods: {
    back() {
      this.$emit("back");
    },
    calculatePrice() {
      this.amount = Object.keys(this.formResponses.plan).reduce(
        (total, service) => {
          return total + this.formResponses.plan[service].amount;
        },
        0
      );
    },
    create() {
      const { complementary, reason, plan } = this.formResponses;
      Object.keys(plan).forEach((service) => {
        delete plan[service].frequency;
        delete plan[service].service_day;
        if (service === "meal") {
          delete plan.meal.meal_per_delivery;
        }
      });

      let payload = {
        complementary,
        reason: complementary ? reason : null,
        user_id: this.customerId,
        plan_details: JSON.stringify(this.sortObject(plan)),
        new_onboarding_flow: true,
      };

      this.creating = true;

      customer
        .orderOneTime(payload.user_id, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("created");
            this.creating = false;
          }
        })
        .catch((error) => {
          this.creating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-service--summary {
  background: var(--eden-grey-septenary);
  border-radius: 12px;
  padding: 15px 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  span {
    color: #798b83;
    width: 40%;
    font-size: 0.875rem;
  }

  p {
    color: var(--eden-grey-secondary);
    font-size: 0.875rem;
  }
}

.is-align-start {
  align-items: start !important;
}
</style>
