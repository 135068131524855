<template>
  <div>
    <eden-overview-card :class="'fixed--height'" :title="'Feedback Records'">
      <template slot="action">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getCustomerFeedback"
        />
      </template>
      <template slot="content">
        <div>
          <eden-loader v-if="loading" />
          <template v-else>
            <el-table :data="pageData" style="width: 100%">
              <el-table-column width="100">
                <template slot="header">
                  <div class="table--header">
                    <span>Order Id</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <router-link
                    class="text-primary"
                    :to="{
                      name: 'orders.order',
                      params: { id: scope.row.customer_order_id },
                    }"
                  >
                    {{ scope.row.customer_order_id }}</router-link
                  >
                </template>
              </el-table-column>
              <el-table-column min-width="100">
                <template slot="header">
                  <div class="table--header">
                    <span>Service</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-tag :type="setServiceType(scope.row.service)">{{
                    formatText(scope.row.service)
                  }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot="header">
                  <div class="table--header">
                    <span>Date</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span class="font-sm">{{
                    formatDate(scope.row.created_at, "ddd do, m, y")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="120">
                <template slot="header"> </template>
                <template v-slot="scope">
                  <span
                    class="text-primary text-cursor"
                    @click="viewCustomerFeedback(scope.row.customer_order_id)"
                  >
                    View history</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <eden-pagination
              v-if="pageData.length"
              :from="pagination.from"
              :to="pagination.to"
              :total="pagination.total"
              :current-page.sync="page"
            />
          </template>
        </div>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import feedback from "@/requests/customers/customer/feedback";

export default {
  name: "CustomerFeedback",
  data() {
    return {
      period: "",
      custom: {
        from: null,
        to: null,
      },
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
      },
      loading: false,
      page: 1,
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      pageData: [],
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    page() {
      this.getCustomerFeedback({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
  },
  created() {
    this.getCustomerFeedback({ period: "thisweek" });
  },
  methods: {
    getCustomerFeedback({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      this.loading = true;
      const params = `page=${this.page}&user_id=${this.customerId}&start_date=${start_date}&end_date=${end_date}`;
      feedback
        .index(params)
        .then((response) => {
          const { status, meta, data } = response.data;
          if (status) {
            this.pageData = data;
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;

            if (this.period === "custom") {
              this.custom.from = start_date;
              this.custom.to = end_date;
            }
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    viewCustomerFeedback(orderId) {
      this.$router.push({
        name: "orders.order",
        params: { id: orderId },
        query: { t: "feedback" },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed--height {
  min-height: 500px;
}
</style>
