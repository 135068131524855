<template>
  <el-dialog
    title="Customer Subscription Cycle"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex" :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="Start Date"
            prop="start_date"
            :rules="validateField()"
          >
            <el-date-picker
              placeholder="Start"
              v-model="form.start_date"
              value-format="yyyy-MM-dd"
              type="date"
              format="dd MMM, yyyy"
              prefix-icon="eden-icon-calendar"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="End Date"
            prop="end_date"
            :rules="validateField()"
          >
            <el-date-picker
              placeholder="End"
              v-model="form.end_date"
              value-format="yyyy-MM-dd"
              type="date"
              format="dd MMM, yyyy"
              prefix-icon="eden-icon-calendar"
              :picker-options="{
                disabledDate: disableStartDate,
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="setSubscriptionCycle"
        >Set Cycle</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import subscription from "@/requests/subscriptions/subscription";

export default {
  name: "SubscriptionCycleSet",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customerEmail: {
      type: String,
      default: "",
    },
    customerType: {
      type: String,
      default: "individual",
    },
  },
  data() {
    return {
      loading: false,
      form: {
        start_date: "",
        end_date: "",
      },
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    disableStartDate(time) {
      if (this.allowAccessFor(["superadmin"])) {
        return false;
      }

      return time.getTime() <= new Date(this.form.start_date).getTime();
    },
    setSubscriptionCycle() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.loading = true;
        const payload = {
          customer_email: this.customerEmail,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        };
        if (this.customerType === "individual") {
          subscription
            .cycle(payload)
            .then((response) => {
              if (response.data.status) {
                this.$message.success(response.data.message);
                this.$emit("success");
                this.closeEvent();
                this.loading = false;
              } else {
                this.$message.error(response.data.message);
                this.loading = false;
              }
            })
            .catch((error) => {
              this.$message.error(error.response.data.message);
              this.loading = false;
            });
        } else {
          subscription
            .corporateCycle(payload)
            .then((response) => {
              if (response.data.status) {
                this.$message.success(response.data.message);
                this.$emit("success", this.form.end_date);
                this.closeEvent();
                this.loading = false;
              } else {
                this.$message.error(response.data.message);
                this.loading = false;
              }
            })
            .catch((error) => {
              this.$message.error(error.response.data.message);
              this.loading = false;
            });
        }

        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  margin: 10px 0;

  span {
    font-weight: bold;
  }
}
</style>
