<template>
  <div>
    <eden-overview-card :title="'Activity Feed'">
      <template slot="action">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getCustomerActivity"
        />
      </template>

      <template slot="content">
        <eden-loader v-if="loading" />
        <template v-else>
          <template v-if="Object.keys(pageData).length">
            <div
              class="activity-item"
              v-for="(activity, date, index) in pageData"
              :key="index"
            >
              <el-tag class="date">
                {{ formatDate(date, "d m, y") }}
              </el-tag>
              <div
                class="activities"
                v-for="(item, index) in activity"
                :key="index"
              >
                <el-row type="flex">
                  <el-col :span="3">
                    <span class="font-sm text-grey-tertiary">
                      {{ formatTime(item.created_at, "12h") }}
                    </span>
                  </el-col>
                  <el-col :span="24" :class="'description'">
                    <span class="font-sm">
                      {{ formatText(item.description) }}
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
            <eden-pagination
              v-if="pageData.length"
              :from="pagination.from"
              :to="pagination.to"
              :total="pagination.total"
              :current-page.sync="page"
            />
          </template>
          <eden-content-empty v-else :text="'No activity'" :size="120" />
        </template>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import individual from "@/requests/customers/individual";

export default {
  name: "CustomerActivities",
  data() {
    return {
      period: "",
      custom: {
        from: null,
        to: null,
      },
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
      },
      loading: false,
      page: 1,
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      pageData: [],
    };
  },
  computed: {
    title() {
      return `${this.pagination.total} Activities`;
    },

    customerId() {
      return this.$route.params.id;
    },
  },

  watch: {
    page() {
      this.getCustomerActivity({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
  },
  created() {
    this.getCustomerActivity({ period: "thisweek" });
  },

  methods: {
    getCustomerActivity({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      this.loading = true;
      const params = `page=${this.page}&start_date=${start_date}&end_date=${end_date}`;
      individual
        .feed(this.customerId, params)
        .then((response) => {
          const { status, meta, data } = response.data;
          if (status) {
            this.setPageData(data);
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;

            if (this.period === "custom") {
              this.custom.from = start_date;
              this.custom.to = end_date;
            }
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData(data) {
      this.pageData = data.reduce((acc, x) => {
        const date = this.formatDate(x.created_at, "y-m-d");
        if (acc[date]) {
          acc[date].push(x);
        } else {
          acc[date] = [x];
        }

        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-item {
  margin-bottom: 32px;

  .date {
    margin-bottom: 24px;
  }

  .el-tag {
    background: var(--eden-blue-septenary);
    color: var(--eden-grey-primary);
    font-size: 0.75rem;
    font-weight: 500;
  }

  .activities {
    padding: 0 10px;
    .el-row {
      align-items: center;
    }

    .description {
      padding: 16px 0;
      border-top: 1px solid #e2e9e6;
    }

    &:last-child {
      .description {
        border-bottom: 1px solid #e2e9e6;
      }
    }
  }
}
</style>
