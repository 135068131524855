export const formatCustomers = (data) => {
  return data.map((customer) => {
    return {
      id: customer.id,
      name: customer?.name?.toLowerCase(),
      email: customer?.email?.toLowerCase(),
      persona: customer?.customer_persona_name,
      status: customer?.customer_status,
      gender: customer?.gender,
      services_rendered: customer?.services_rendered,
      gardener: customer?.gardener,
      signup_meta: {
        source: customer.signup_meta ? customer.signup_meta.source : "-",
        medium: customer.signup_meta ? customer.signup_meta.medium : "-",
        content: customer.signup_meta ? customer.signup_meta.content : "-",
        referrer: customer.signup_meta ? customer.signup_meta.referrer : "-",
      },
      phone_number: customer?.phone_number,
      location_area: customer?.location_area || "-",
      zone: customer?.cluster || "-",
      customer_address: customer?.customer_address || "-",
      created_at: customer.created_at || "-",
      created_from: customer.created_from || "-",
      updated_at: customer.updated_at || "-",
      billing_date:
        customer.next_billing_date && customer.next_billing_date.length
          ? customer.next_billing_date[0].next_charge_date
          : null,
    };
  });
};

export const formatSignupMeta = (signup_meta) => {
  let metaCheck = {
    "google.com": "Google",
    "t.co": "Twitter",
    "facebook.com": "Facebook",
    "linktr.ee": "Linktree",
  };
  return {
    source:
      signup_meta && signup_meta.source
        ? signup_meta.source
        : signup_meta && signup_meta.referrer
        ? metaCheck[
            Object.keys(metaCheck).filter((referrer) => {
              return signup_meta.referrer.includes(referrer);
            })
          ]
        : "-",
    medium: signup_meta ? signup_meta.medium : "-",
    content: signup_meta ? signup_meta.content : "-",
  };
};
