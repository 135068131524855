<template>
  <div>
    <eden-overview-card title="Gardener's Log">
      <template slot="actions">
        <router-link :to="{ name: 'customer.gardener-history' }">
          <span class="font-sm text-primary">View all log</span>
        </router-link>
      </template>
      <template slot="content">
        <template v-if="loading">
          <p>Loading</p>
        </template>
        <template v-else>
          <div v-if="gardener_logs.length">
            <div
              v-for="(log, index) in gardener_logs"
              :key="index"
              class="gardener--log is-flex justify-between align-center"
            >
              <div class="head">
                <div class="font-sm text-bold">
                  {{ formatName(log.gardener_name) }}
                </div>
                <div class="date">
                  <span v-if="index === 0" class="font-sm">Current</span>

                  <span
                    v-else
                    class="is-flex justify-between align-center font-sm"
                  >
                    <span>
                      {{ formatDateAndTime(log.assigned_at, "do m, y") }} -
                    </span>

                    <span>
                      {{ formatDateAndTime(log.changed_at, "do m, y") }}
                    </span>
                  </span>
                </div>

                <span class="font-sm">
                  Changed by
                  <span class="text-primary text-bold">
                    {{ formatName(log.assignment_made_by) }}</span
                  >
                </span>
              </div>
              <div v-if="index > 0" class="body">
                <el-button type="info" plain @click="viewChat(index)">
                  View Chat
                </el-button>
              </div>
              <customer-gardener-chat
                :show.sync="showChat"
                :log="gardener_log"
              />
            </div>
          </div>
          <eden-content-empty v-else :text="'No log'" :size="100" />
        </template>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import customer from "@/requests/customers/customer/index";
import CustomerGardenerChat from "@/components/Customers/Individual/Gardener/Chat/CustomerGardenerChat.vue";
export default {
  name: "CustomerGardenerLog",
  components: { CustomerGardenerChat },
  props: {
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      gardener_logs: [],
      gardener_log: {},
      showChat: false,
    };
  },
  created() {
    this.getGardenerLogs();
  },
  methods: {
    getGardenerLogs() {
      this.loading = true;
      const id = this.customer.id;
      customer
        .gardenersLog(id)
        .then((response) => {
          if (response.data.status) {
            this.gardener_logs = this.formatGardenerLog(response.data.data, 2);
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },
    viewChat(index) {
      this.gardener_log = this.gardener_logs[index];
      this.showChat = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.gardener {
  &--log {
    color: #21312a;
    margin-bottom: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid #f0f4f2;
    }
    .head {
      margin-bottom: 24px;

      > * {
        margin-bottom: 8px;
      }
    }

    .date {
      color: #1d352a;
      margin-bottom: 12px;
    }
  }
}

.el-button--info.is-plain {
  background: #f0f4f2;
  color: #0f241b;
  border-radius: 0;
  border: 0;
  margin-bottom: 25px;
}
</style>
