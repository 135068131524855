<template>
  <el-dialog :title="title" :visible.sync="setShow" width="35%" @close="close">
    <el-form :model="form" label-position="top" ref="form">
      <el-form-item label="Note" prop="note" :rules="validateField()">
        <el-input type="textarea" rows="4" v-model="form.note"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="adding"
        :disabled="!form.note"
        @click="add"
        >Add Note
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="updating"
        :disabled="!form.note"
        @click="update"
        >Save changes
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import notes from "@/requests/customers/customer/notes";

export default {
  name: "CustomerGardenerNoteAdd",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    note: {
      type: Object,
      default() {
        return {};
      },
    },
    customerId: {
      type: [String, Number],
      default: "",
    },
    gardenerId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      form: {
        note: "",
      },
      adding: false,
      updating: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return this.action === "add" ? "Add New Note" : "Edit Note";
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.note = this.note.note;
      }
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.adding = true;

        let payload = {
          user_id: this.customerId,
          note: this.form.note,
        };

        const { gardener_id, logged_in_userId } = this.$store.getters.user;
        if (gardener_id === this.gardenerId) {
          payload.gardener_id = gardener_id;
        } else {
          payload.actor_id = logged_in_userId;
        }

        notes
          .add(payload)
          .then((response) => {
            const { status, data } = response;
            if (status) {
              this.close();
              this.$emit("success");
              this.adding = false;
              this.$message.success(data.message);
            } else {
              this.adding = false;
              this.$message.error(data.message);
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        const payload = {
          note_id: this.note.id,
          note: this.form.note,
        };

        notes
          .update(payload)
          .then((response) => {
            const { status, data } = response;
            if (status) {
              this.close();
              this.$emit("success");
              this.updating = false;
              this.$message.success(data.message);
            } else {
              this.updating = false;
              this.$message.error(data.message);
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
