export const mealIndex = () => {
  return [
    "first_meal",
    "second_meal",
    "extras_meal",
    "extras_two_meal",
    "extras_three_meal",
    "extras_four_meal",
    "extras_five_meal",
    "extras_six_meal",
    "extras_seven_meal",
    "extras_eight_meal",
    "extras_nine_meal",
    "extras_ten_meal",
    "extras_eleven_meal",
    "extras_twelve_meal",
    "extras_thirteen_meal",
    "extras_fourteen_meal",
    "extras_fifteen_meal",
    "extras_sixteen_meal",
    "extras_seventeen_meal",
    "extras_eighteen_meal",
  ];
};

export const mealActivities = (order, meal, sp) => {
  const extra = order.extra_details || {};
  const breakdown =
    order.service_breakdown &&
    order.service_breakdown.filter(
      (item) => item.specifics.toLowerCase() === meal.toLowerCase()
    );
  const activities = breakdown && breakdown.length ? breakdown[0] : {};
  const activitiesList = {
    plated: {
      label: "Plated",
      status: !!activities.is_plated,
      time: activities.is_plated_time,
    },
    sealed: {
      label: "Sealed",
      status: !!activities.is_sealed,
      time: activities.is_sealed_time,
    },
    sleeved: {
      label: "Sleeved",
      status: !!activities.is_sleeved,
      time: activities.is_sleeved_time,
    },
    packed: {
      label: "Packed",
      status: !!extra.is_packed,
      time: extra.is_packed_time,
    },
    dispatched: {
      label: "Dispatched",
      status: !!extra.is_dispatched,
      time: extra.is_dispatched_time,
    },
    delivery: {
      label: "Delivered",
      status: !!extra.is_delivered,
      time: extra.is_delivered_at,
    },
  };

  if (sp.toLowerCase().includes("juice")) {
    delete activitiesList.plated;
    delete activitiesList.sealed;
    delete activitiesList.sleeved;
  }
  return activitiesList;
};

export const mealsInMenu = (menu) => {
  const concatenate = (...args) => {
    const combo = args.filter((el) => {
      return el ? el : false;
    });
    return combo.join(", ");
  };

  let newMenu = [];
  menu.forEach((meal) => {
    const { preset_combos_full: combos } = meal;
    if (combos && combos.length) {
      combos.forEach((combo) => {
        const mealName = concatenate(
          meal.name,
          combo.main_sides,
          combo.protein_sides,
          combo.other_sides,
          combo.sauce_sides,
        );
        newMenu.push({
          name: mealName.toLowerCase(),
          calorie: combo.calorie || "",
        });
      });
    } else {
      newMenu.push({
        name: meal.name.toLowerCase(),
        calorie: "",
      });
    }
  });
  return newMenu;
};

export const mealsInOneTimeMenu = (menu) => {
  const concatenate = (...args) => {
    const combo = args.filter((el) => {
      return el ? el : false;
    });
    return combo.join(", ");
  };
  let newMenu = [];
  menu.item_groups.forEach((itemGroup) => {
    const { menu_combo_items: combos } = itemGroup;
    if(combos && combos.length){
      combos.forEach((combo) => {
      const sideItemNames =  combo.side_items.map((side) => side.meal_name).join(", ");
      const mealName = concatenate(itemGroup.main_meal_name, sideItemNames);
      newMenu.push({
        name: mealName.toLowerCase(),
        calorie: combo.calorie || "",
      });
    });
    }else{
      newMenu.push({
        name: itemGroup.main_meal_name.toLowerCase(),
        calorie: "",
      });
    }
  });
  return newMenu;
};
