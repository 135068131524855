<template>
  <el-drawer :visible.sync="setShow" direction="rtl" @close="closeEvent">
    <template slot="title">
      <span class="text-grey-primary">One-Time Order</span>
    </template>
    <template v-if="step === 1">
      <order-one-time-form
        :form-responses.sync="formResponses"
        :customer-id="customerId"
        @summarise="summarise"
        :menu-type-variation="menuTypeVariation"
      />
    </template>
    <template v-else>
      <order-one-time-summary
        :form-responses="formResponses"
        :customer-id="customerId"
        @back="step = 1"
        @created="emitUpdate"
      />
    </template>
  </el-drawer>
</template>

<script>
import OrderOneTimeForm from "./OrderOneTimeForm";
import OrderOneTimeSummary from "./OrderOneTimeSummary";

export default {
  name: "CustomerOrderOneTime",
  components: {
    OrderOneTimeForm,
    OrderOneTimeSummary,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [String, Number],
      default: "",
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      step: 1,
      formResponses: {},
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.step = 1;
      this.formResponses = {};
    },
    summarise() {
      this.step = 2;
    },
    emitUpdate() {
      this.$emit("success");
      this.setShow = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
