<template>
  <eden-information-card>
    <template slot="content">
      <div class="customer-profile">
        <eden-avatar :src="data.avatar" :size="70" />
        <h6 class="name font-lg">{{ data.name }}</h6>
        <p class="email font-base text-grey-tertiary">
          {{ formatDeletedEmail(data.email) }}
        </p>
        <div class="tags">
          <eden-tag :value="data.customer_status" />
          <el-tag v-if="!deleted && data.ibna !== 'active'" type="danger">
            IBNA Inactive
          </el-tag>
        </div>
        <router-link
          class="rewards"
          :to="{ name: 'customers.individual.rewards' }"
          >Open Referrals & Rewards Centre</router-link
        >
        <el-button type="plain" size="medium" @click="edit"
          >Edit Profile</el-button
        >
      </div>
      <customers-wallet-card :customer="data" />
      <customer-information
        v-if="!deleted"
        :information="information"
        :customer="data"
      />
    </template>
  </eden-information-card>
</template>

<script>
import CustomerInformation from "@/components/Customers/Individual/Profile/CustomerInformation";
import CustomersWalletCard from "./CustomersWalletCard.vue";

export default {
  name: "CustomerProfileSummary",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { CustomerInformation, CustomersWalletCard },
  data() {
    return {};
  },
  computed: {
    information() {
      return [
        {
          label: "Phone number",
          text: this.formatPhoneNumber(this.data.phone_number),
        },
        {
          label: "Address",
          text: this.data.address,
        },
        {
          label: "Gardener",
          text: this.data.gardener,
        },
        {
          label: "Customer Type",
          text: this.data.corporation
            ? `${this.data.corporation} Employee`
            : this.formatPersona(this.data.persona),
        },
        {
          label: "Next Charge Date",
          text: this.formatDate(this.data.next_charge_date, "dddd do, m, y"),
        },
      ];
    },
    deleted() {
      return this.data.customer_status === "deleted" ? true : false;
    },
  },
  methods: {
    edit() {
      this.$router.push({ name: "customers.individual.edit" });
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  border-radius: 16px !important;
  margin-bottom: 16px !important;
}
.customer-profile {
  margin-bottom: 30px;
  text-align: center;

  .name {
    font-family: "CerebriSans", sans-serif;
    font-weight: 500;
    margin-bottom: 7px;
  }

  .email {
    font-family: "CerebriSans", sans-serif;
    font-weight: 400;
  }

  .tags {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
  }

  .rewards {
    display: inline-block;
    margin: 5px 0;
    font-size: 0.875rem;
    color: var(--eden-green-primary);
  }

  .el-button {
    margin: 10px auto;
  }
}
</style>
