<template>
  <div class="schedule-card">
    <el-collapse accordion>
      <el-collapse-item
        :name="service"
        :itemprop="service"
        :class="service.toLowerCase()"
      >
        <template slot="title">
          <div class="schedule-card__title">
            <div>
              <span class="font-sm service">
                <span
                  :class="{
                    paused: order.paused,
                  }"
                ></span>
                {{ formatText(service) }} {{ isOneTime ? "(One-time)" : "" }}
              </span>
              <span class="font-sm status"> {{ status }}</span>
            </div>
            <div>
              <span class="font-sm date">{{ deliveryDate }}</span>
              <router-link
                :to="{
                  name: 'orders.order',
                  params: { id: orderId },
                }"
                ><span class="font-sm order"> View Order </span>
              </router-link>
            </div>
          </div>
        </template>
        <div class="schedule-card__details">
          <template v-if="service.includes('meal') && meals.length">
            <div
              v-for="(meal, index) in meals"
              class="service-progress"
              :key="index"
            >
              <span class="font-sm">{{ formatMealName(meal.specifics) }}</span>
              <eden-progress-bar :activities="getMealActivities(meal.specifics)" />
            </div>
          </template>
          <template v-else-if="service.includes('meal')">
            <el-tag>Meal not provisioned</el-tag>
          </template>
          <template v-else-if="service.includes('beauty') && order.extra_details!== null && order.extra_details.services.length">
            <!-- {{order.extra_details}} -->
            <div
              v-for="(item, index) in order.extra_details.services"
              class="service-progress"
              :key="index"
            >
              <span class="font-sm">{{ item.beauty_category.name }}</span>
              <eden-progress-bar :activities="getBeautyActivities(item)" />
            </div>
          </template>
          <template v-else-if="service.includes('beauty')">
            <el-tag>Order not provisioned</el-tag>
          </template>
          <template v-else>
            <eden-progress-bar
              :activities="activities"
              :wrap-bar="service === 'laundry'"
            />
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { BEAUTY_ACTIVITIES } from "@/components/Orders/Order/Beauty/beauty.config";
import { CLEANING_ACTIVITIES } from "@/components/Orders/Order/Cleaning/cleaning.config";
import { LAUNDRY_ACTIVITIES } from "@/components/Orders/Order/Laundry/laundry.config";
import { mealActivities } from "@/components/Orders/Order/Meal/meal.config";

export default {
  name: "CustomerScheduleCard",
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    orderId() {
      return this.order.order_id;
    },
    service() {
      return this.order.service.toLowerCase();
    },
    status() {
      return this.formatText(this.order.completed_status);
    },
    meals() {
      return this.order.extra_details
    },
    isOneTime() {
      return this.order.is_one_time_order;
    },
    pickupDate() {
      return this.formatDate(this.order.pickup_from_customer, "dddd do, m, y");
    },
    deliveryDate() {
      return this.formatDate(this.order.deliver_to_customer, "dddd do, m, y");
    },
    activities() {
      switch (this.service.toLowerCase()) {
        case "beauty":
          console.log(this.order)
          return BEAUTY_ACTIVITIES(this.order);
        case "cleaning":
          return CLEANING_ACTIVITIES(this.order);
        case "laundry":
          return LAUNDRY_ACTIVITIES(this.order);
        default:
          return {};
      }
    },
  },
  methods: {
    getMealActivities(meal) {
      let order = this.parseData(this.order)
      order.service_breakdown = this.order.extra_details
      order.extra_details = {
        is_packed: order.is_packed,
        is_packed_time: order.is_packed_time,
        is_dispatched: order.is_dispatched,
        is_dispatched_time: order.is_dispatched_time,
        is_delivered: order.is_delivered,
        is_delivered_time: order.is_delivered_time
      }
      return mealActivities(order, meal, "kmd");
    },
    getBeautyActivities(order) {
      // let order = this.parseData(this.order)
      // order.service_breakdown = this.order.extra_details
      // order.extra_details = {
      //   is_packed: order.is_packed,
      //   is_packed_time: order.is_packed_time,
      //   is_dispatched: order.is_dispatched,
      //   is_dispatched_time: order.is_dispatched_time,
      //   is_delivered: order.is_delivered,
      //   is_delivered_time: order.is_delivered_time
      // }÷
      return BEAUTY_ACTIVITIES(order);
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-card {
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  &__title {
    width: 100%;

    > div {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .service {
        color: var(--eden-grey-primary);
        font-weight: 500;
        display: flex;
        align-items: center;

        span {
          height: 8px;
          width: 8px;
          border-radius: 100px;
          margin-right: 8px;
          background: var(--eden-green-primary);
          display: block;

          &.paused {
            background: var(--eden-orange-primary);
          }
        }
      }

      .status {
        color: var(--eden-grey-secondary);
      }

      .date {
        color: var(--eden-grey-tertiary);
        font-size: 0.825rem;
      }

      .order {
        font-weight: 500;
        color: var(--eden-grey-primary);
        text-decoration: underline;
      }
    }
  }

  &__details {
    margin-top: 32px;

    .service-progress {
      > span {
        color: var(--eden-grey-primary);
        font-weight: 500;
      }
    }
  }
}
</style>
