<template>
  <eden-overview-card :title="'Schedule'">
    <template slot="subtitle" v-if="!deletedStatus">
      <el-dropdown @command="setPeriod">
        <span class="font-sm text-cursor">
          ({{ periods[period] }})
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(value, key, i) in periods"
            :key="i"
            :command="key"
            :class="{ selected: key === period }"
            >{{ value }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template slot="action">
      <router-link :to="{ name: 'customers.individual.orders' }">
        <span class="font-base text-primary">All Time Orders</span>
      </router-link>
    </template>
    <template slot="content">
      <eden-loader v-if="loading" />
      <template v-else>
        <template v-if="!deletedStatus && orders.length">
          <div class="customer-schedule">
            <customer-schedule-card
              v-for="(order, index) in orders"
              :key="index"
              :order="order"
            />
          </div>
        </template>
        <p v-else class="empty-indicator font-sm text-grey-tertiary">
          This customer {{ period.includes("last") ? "had" : "has" }} no orders
          <span v-if="!deletedStatus">{{ periods[period].toLowerCase() }}</span>
        </p>
      </template>
    </template>
  </eden-overview-card>
</template>

<script>
import CustomerScheduleCard from "./CustomerScheduleCard.vue";
import customer from "@/requests/customers/customer";

export default {
  name: "CustomerSchedule",
  components: { CustomerScheduleCard },
  props: {
    deletedStatus: {
      type: Boolean,
      return: false,
    },
  },
  data() {
    return {
      loading: false,
      period: "thisweek",
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        nextweek: "Next week",
        thismonth: "This month",
      },
      orders: [],
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    period() {
      this.getCustomerSchedule();
    },
  },
  created() {
    this.getCustomerSchedule();
  },
  methods: {
    setPeriod(period) {
      this.period = period;
    },
    getCustomerSchedule() {
      this.loading = true;
      customer
        .schedule({ id: this.customerId, period: this.period, page: 1 })
        .then((response) => {
          if (response.data.status) {
            this.orders = response.data.data.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-schedule {
  min-height: 100px;
  max-height: 250px;
  overflow-y: scroll;
}
</style>
