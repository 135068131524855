<template>
  <div class="individual-customer-profile">
    <div v-if="loading">Loading</div>
    <template v-else>
      <eden-page-header :title="'Customers'" :subtitle="customerName">
        <template slot="actions" v-if="!deleted">
          <div class="is-flex is-flex-wrap align-center">
            <customer-actions
              :customer="customer"
              @fetch="getCustomer"
              @update="updateCustomer"
            >
              <template slot="actions-trigger">
                <el-button type="plain">
                  Actions <i class="eden-icon-arrow-down"></i>
                </el-button>
              </template>
            </customer-actions>
            <el-button type="primary" @click="showOneTimeOrderCreate = true">
              Add One-time Order
            </el-button>
          </div>
        </template>
      </eden-page-header>
      <div class="body is-flex">
        <div class="summary">
          <customer-profile-summary :data="customerProfile" />
          <customer-profile-information :data="customerProfileInformation" />
          <customer-profile-preferences
            v-if="!deleted"
            :data="customerProfilePreferences"
          />
        </div>
        <div class="detail">
          <el-tabs v-model="tab" @tab-click="updateRouteQuery">
            <el-tab-pane label="Overview" name="overview">
              <customer-overview
                :customer="customer"
                :deleted-status="deleted"
                @subscription-activated="getCustomer"
              />
            </el-tab-pane>
            <el-tab-pane label="Feedback Records" name="feedback">
              <customer-feedback />
            </el-tab-pane>
            <el-tab-pane label="Activity Feed" name="activities">
              <customer-activities />
            </el-tab-pane>
            <el-tab-pane label="Gardener Notes" name="gardener-notes">
              <customer-gardener-notes
                :customer-id="customerId"
                :deleted-status="deleted"
                :gardener-id="customer.gardener_id"
              />
            </el-tab-pane>
            <el-tab-pane label="Issue logs" name="issue-logs">
              <order-issues-log
                ref="issueLog"
                :customer-id="customerId"
                @logged="issuesLog = true"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </template>
    <customer-onetime-orders-create
      :show.sync="showOneTimeOrderCreate"
      :customer-id="customer.id"
      @success="getCustomer"
    />
  </div>
</template>

<script>
import OrderIssuesLog from "@/components/Orders/Order/OrderIssuesLog";
import CustomerProfileSummary from "@/components/Customers/Individual/Profile/CustomerProfileSummary.vue";
import CustomerProfileInformation from "@/components/Customers/Individual/Profile/CustomerProfileInformation";
import CustomerProfilePreferences from "@/components/Customers/Individual/Profile/CustomerProfilePreferences";

import CustomerOverview from "@/components/Customers/Individual/Overview/CustomerOverview";
import CustomerFeedback from "@/components/Customers/Individual/Feedback/CustomerFeedback";
import CustomerActivities from "@/components/Customers/Individual/Activities/CustomerActivities";
import CustomerGardenerNotes from "@/components/Customers/Individual/Gardener/Notes/CustomerGardenerNotes";

import CustomerOnetimeOrdersCreate from "@/components/Customers/Individual/Actions/Orders/OneTime/CustomerOnetimeOrdersCreate";
import individual from "@/requests/customers/individual";
import CustomerActions from "@/components/Customers/Individual/Actions/CustomerActions";

export default {
  name: "CustomerIndividual",
  components: {
    OrderIssuesLog,
    CustomerActions,
    CustomerProfileSummary,
    CustomerProfileInformation,
    CustomerProfilePreferences,
    CustomerOverview,
    CustomerFeedback,
    CustomerActivities,
    CustomerGardenerNotes,
    CustomerOnetimeOrdersCreate,
  },
  data() {
    return {
      issuesLog: false,
      loading: false,
      tab: "overview",
      showOneTimeOrderCreate: false,
      customer: {},
    };
  },
  computed: {
    deleted() {
      return this.customer.customer_status === "deleted" ? true : false;
    },
    customerId() {
      return this.$route.params.id;
    },
    customerName() {
      return this.customer.name ? this.formatName(this.customer.name) : "";
    },
    customerCompany() {
      return this.customer.user_corporation_name
        ? this.formatName(this.customer.user_corporation_name)
        : "";
    },
    customerProfile() {
      const subscription =
        this.customer.next_billing_date?.length &&
        this.customer.next_billing_date[0];

      return {
        avatar: this.customer.avatar,
        id: this.customer.id,
        name: this.customerName,
        email: this.customer.email,
        customer_status: this.customer.customer_status,
        ibna: this.customer.ibna,
        phone_number: this.customer.phone_number,
        address: this.customer.home_address,
        gardener: this.customer.gardener,
        persona: this.customer.customer_persona_name,
        corporation: this.customer.user_corporation_name,
        wallet_balance: this.customer.wallet_balance,
        next_charge_date: subscription && subscription.next_charge_date,
      };
    },
    customerProfileInformation() {
      return {
        birthday: this.customer.birthday,
        joined: this.customer.created_at,
        joined_via: this.customer.is_created_via_lighthouse,
        updated: this.customer.updated_at,
        player_ids: this.customer.player_ids,
        sendbird_id: this.customer.sendbird_id,
        getstream_id: this.customer.getstream_id,
        created_from: this.customer.created_from,
        signup_meta: this.customer.signup_meta,
        deleted_at: this.customer.deleted_at,
      };
    },
    customerProfilePreferences() {
      const plan =
        this.customer.next_billing_date?.length &&
        this.customer.next_billing_date[0].plan;
      return plan
        ? Object.keys(plan).filter((service) => service !== "meal")
        : [];
    },
  },
  created() {
    let { t } = this.$route.query;
    this.tab = t ? t : "overview";
  },
  mounted() {
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      this.loading = true;
      individual
        .get(this.customerId)
        .then((response) => {
          this.customer = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateRouteQuery(tab) {
      const { t } = this.$route.query;

      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "customers.individual",
        params: { id: this.customerId },
        query: { t: tab.name },
      });
    },
    updateCustomer(update) {
      this.customer = {
        ...this.customer,
        ...update,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 320px;

.individual-customer-profile {
  .body {
    margin-top: 45px;
    .detail {
      margin-left: 64px;
      width: calc(100% - #{$-summary-width});
    }
  }

  @media (max-width: 992px) {
    .body {
      flex-wrap: wrap;
      .detail {
        margin: 0;
        width: 100%;
      }
    }
  }
}
</style>
