<template>
  <div>
    <eden-overview-card :title="'Gardener Notes'">
      <template slot="action" v-if="!deletedStatus">
        <el-button
          v-if="allowActions"
          type="text"
          icon="el-icon-plus"
          @click="setNote('add')"
        >
          Add New Note
        </el-button>
      </template>
      <template slot="content">
        <template v-if="notes.length">
          <customer-gardener-note
            v-for="(note, index) in notes"
            :key="index"
            :note="note"
            :allow-actions="allowActions"
            @edit="setNote('edit', note.id, note.note)"
            @deleted="deleteNote(note.id)"
          />
        </template>
        <div v-else>
          <p class="empty-indicator font-sm text-grey-tertiary">
            No notes added.
          </p>
        </div>
      </template>
    </eden-overview-card>
    <customer-gardener-note-form
      :action="gardenerNoteForm.action"
      :show.sync="gardenerNoteForm.visibility"
      :customer-id="customerId"
      :gardener-id="gardenerId"
      :note="gardenerNoteForm.note"
      @success="getNotes"
    />
  </div>
</template>

<script>
import CustomerGardenerNoteForm from "./CustomerGardenerNoteForm";
import CustomerGardenerNote from "@/components/Customers/Individual/Gardener/Notes/CustomerGardenerNote";

import notes from "@/requests/customers/customer/notes";

export default {
  name: "CustomerGardenerNotes",
  props: {
    customerId: {
      type: [String, Number],
      default: "",
    },
    gardenerId: {
      type: [String, Number],
      default: "",
    },
    deletedStatus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomerGardenerNote,
    CustomerGardenerNoteForm,
  },
  data() {
    return {
      gardenerNoteForm: {
        action: "add",
        visibility: false,
        note: {},
      },
      notes: [],
      note: {},
      showGardenerNoteForm: false,
    };
  },
  computed: {
    allowActions() {
      if (this.allowAccessFor(["admin", "gardener", "gardener_pro"])) {
        return true;
      }

      const { gardener_id } = this.$store.getters.user;
      return parseInt(gardener_id) === parseInt(this.gardenerId);
    },
  },
  created() {
    this.getNotes();
  },
  methods: {
    getNotes() {
      notes
        .index(this.customerId)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.notes = data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message.error(data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setNote(action, id, note) {
      this.gardenerNoteForm.note.id = action === "add" ? null : id;
      this.gardenerNoteForm.note.note = action === "add" ? "" : note;
      this.gardenerNoteForm.action = action;
      this.gardenerNoteForm.visibility = true;
    },
    deleteNote(id) {
      this.notes = this.notes.filter((note) => note.id !== id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
