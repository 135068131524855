import axios from "axios";

export default {
  index(id) {
    return axios.get(`crm/customer/note/activity?user_id=${id}`);
  },

  add(payload) {
    return axios.post("crm/customer/note/activity", payload);
  },

  update(payload) {
    return axios.post("crm/customer/note/activity/update", payload);
  },

  delete(payload) {
    return axios.post("crm/customer/note/activity/delete", payload);
  },
};
