import axios from "axios";

export default {
  get(id, service) {
    return axios.get(`preferences/${id}/general/${service}`);
  },

  add(payload) {
    return axios.post("preferences/general/add", payload);
  },

  update(id, payload) {
    return axios.put(`preferences/${id}`, payload);
  },

  delete(customerId, payload) {
    return axios.post(`preferences/${customerId}/expunge`, payload);
  },
};
