<template>
  <div>
    <eden-overview-card :title="'Food Selection Activities'">
      <template slot="subtitle">
        <el-dropdown @command="setPeriod">
          <span class="font-sm text-cursor">
            ({{ periods[period] }})
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key, i) in periods"
              :key="i"
              :command="key"
              :class="{ selected: key === period }"
              >{{ value }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template slot="content">
        <template v-if="loading">
          <p>Loading</p>
        </template>
        <template v-else>
          <template v-if="validFoodSelections">
            <eden-information-card>
              <template slot="content">
                <div class="food-selection">
                  <div
                    v-for="(activity, index) of Object.keys(activities)"
                    :key="index"
                    class="selection"
                  >
                    <span class="font-sm"
                      >{{ formatDate(activity, "dddd do, m, y") }} -
                      {{ formatTime(activity, "12h") }}</span
                    >
                    <span
                      class="font-sm text-primary text-cursor"
                      @click="setActivity(activity)"
                    >
                      See Meals</span
                    >
                  </div>
                </div>
              </template>
            </eden-information-card>
          </template>
          <p v-else class="empty-indicator font-sm text-grey-tertiary">
            This customer
            {{ period.includes("last") ? "didn't make" : "hasn't made" }} any
            selection
            {{ periods[period].toLowerCase() }}
          </p>
        </template>
      </template>
    </eden-overview-card>
    <customer-food-selection :show.sync="showActivity" :activity="activity" />
  </div>
</template>

<script>
import CustomerFoodSelection from "@/components/Customers/Individual/Overview/FoodSelections/CustomerFoodSelection";

import customer from "@/requests/customers/customer/index";

export default {
  name: "CustomerFoodSelections",
  components: {
    CustomerFoodSelection,
  },
  data() {
    return {
      loading: false,
      period: "thisweek",
      periods: {
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
      },
      activities: {},
      showActivity: false,
      activity: {},
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    validFoodSelections() {
      return !!Object.keys(this.activities).length;
    },
  },
  watch: {
    period() {
      this.getFoodSelections();
    },
  },
  created() {
    this.getFoodSelections();
  },
  methods: {
    setPeriod(period) {
      this.period = period;
    },
    getFoodSelections() {
      this.loading = true;
      customer
        .foodActivities(this.customerId, this.period)
        .then((response) => {
          if (response.data.status) {
            this.setActivities(response.data.data);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setActivities(data) {
      this.activities = {};
      data.forEach((item) => {
        if (!this.activities[item.created_at]) {
          this.activities[item.created_at] = [];
        }
        this.activities[item.created_at].push(item);
      });
    },
    setActivity(activity) {
      this.activity = {
        date: activity,
        items: this.activities[activity],
      };
      console.log(this.activity);
      this.showActivity = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  padding-top: 0;
}

.food-selection {
  .selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: #1d352a;
    font-weight: 400;

    &:not(:last-child) {
      border-bottom: 1px solid #f0f4f2;
    }
  }
}
</style>
