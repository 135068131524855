<template>
  <div>
    <div class="note-item">
      <el-row type="flex">
        <el-col :span="2">
          <span class="font-sm text-grey-tertiary">
            <img class="icon" :src="getImage(avatar)" :alt="name" />
          </span>
        </el-col>
        <el-col :span="22" :class="'description'">
          <span class="font-sm name">
            {{ name }}
          </span>
          <span class="font-sm text-grey-tertiary">
            {{ formatDateAndTime(note.created_at, "mo d, y") }}
          </span>
          <span class="font-sm">
            {{ note.note }}
          </span>
        </el-col>
        <div class="action">
          <el-dropdown
            v-if="allowAccessFor(['superadmin'])"
            class="more"
            trigger="click"
            placement="'bottom'"
            @command="command"
          >
            <span class="el-dropdown-link">
              <i class="eden-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="eden-icon-edit" command="edit"
                >Edit</el-dropdown-item
              >
              <el-dropdown-item icon="eden-icon-delete" command="delete"
                >Delete</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else class="edit-note" @click="command('edit')">
            <i class="eden-icon-edit"></i> Edit note
          </div>
        </div>
      </el-row>
    </div>
    <eden-confirm-dialog
      title="Delete This Note"
      button-type="danger"
      button-text="Delete"
      :show.sync="confirmDelete"
      @confirm="deleteNote"
    >
      <p>
        Are you sure you want to delete this note? This action cannot be
        reversed.
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import notes from "@/requests/customers/customer/notes";

export default {
  name: "CustomerGardenerNote",
  props: {
    note: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      confirmDelete: false,
    };
  },
  computed: {
    name() {
      const { actor, gardener } = this.note;
      return actor ? actor.name : gardener.name;
    },
    avatar() {
      const { actor, gardener } = this.note;
      return actor ? actor.avatar : gardener.avatar;
    },
  },
  methods: {
    command(command) {
      if (command === "edit") {
        this.$emit("edit");
      } else {
        this.confirmDelete = true;
      }
    },
    deleteNote() {
      notes
        .delete({ note_id: this.note.id })
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.$emit("deleted");
            this.confirmDelete = false;
            this.$message.error(data.message);
          } else {
            this.$message.error(data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.note-item {
  padding-bottom: 8px;
  position: relative;
  margin-bottom: 24px;
  border-bottom: 1px solid #f0f4f2;

  .icon {
    width: 27px;
    height: 27px;
    border-radius: 100%;
  }

  .description {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 500;
      margin-bottom: 4px;
    }
    span {
      margin-bottom: 16px;
    }
  }

  .action {
    position: absolute;
    right: 0;

    .el-dropdown-menu {
      left: unset;
      right: 0;
      top: 40%;

      &__item {
        display: flex;
        align-items: center;
      }
    }

    .edit-note {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      color: var(--eden-grey-tertiary);
      cursor: pointer;

      i {
        font-size: 1rem;
        margin-right: 5px;
      }
    }
  }
}
</style>
