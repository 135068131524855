<template>
  <eden-overview-card :title="'Pauses'">
    <template slot="subtitle">
      <span class="font-sm"> ({{ cycleStart }} - {{ cycleEnd }}) </span>
    </template>
    <template slot="actions">
      <router-link :to="{ name: 'customer.subscriptions' }">
        <span class="font-sm text-primary">View history</span>
      </router-link>
    </template>

    <template slot="content">
      <template v-if="loading">
        <p>Loading</p>
      </template>
      <template v-else>
        <template v-if="validLogs">
          <eden-information-card>
            <template slot="content">
              <div class="pauses">
                <customer-pause-log
                  v-for="(log, index) in subscription.logs"
                  :key="index"
                  :log="log"
                  :customer-plan="customerPlan"
                  @set-amount="setOutstandingAmount"
                />
                <div class="pauses-total">
                  <span class="font-base"> Total amount owed: </span>
                  <span class="total"
                    >{{ formatPrice(outstandingAmount) }}
                  </span>
                </div>
              </div>
            </template>
          </eden-information-card>
        </template>
        <p v-else class="empty-indicator font-sm text-grey-tertiary">
          There are no pauses in this subscription cycle yet.
        </p>
      </template>
    </template>
  </eden-overview-card>
</template>
<script>
import CustomerPauseLog from "./CustomerPauseLog";

import { formatPauseLogs } from "./format-data";
import customer from "@/requests/customers/customer";

export default {
  name: "CustomerPauses",
  props: {
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CustomerPauseLog,
  },
  data() {
    return {
      loading: false,
      subscription: {
        start: "",
        end: "",
        logs: [],
      },
      logs: [],
      outstandingAmount: 0,
    };
  },
  computed: {
    validLogs() {
      return !!this.subscription.logs.length;
    },
    cycleStart() {
      return this.subscription.logs.length
        ? this.formatDate(this.subscription.start, "do m, y")
        : "";
    },
    cycleEnd() {
      return this.subscription.logs.length
        ? this.formatDate(this.subscription.end, "do m, y")
        : "";
    },
    customerPlan() {
      const subscription =
        this.customer.next_billing_date?.length &&
        this.customer.next_billing_date[0];
      return subscription ? subscription.plan : {};
    },
  },
  created() {
    const services = Object.keys(this.customerPlan);

    if (services.length) {
      this.getPauses();
    }
  },
  methods: {
    getPauses() {
      this.loading = true;
      const email = this.customer.email;

      customer
        .pauses(email)
        .then((response) => {
          if (response.data.status) {
            const { logs, outstanding_services } = response.data.data;
            this.subscription.start = logs[0].subscription_start_date;
            this.subscription.end = logs[0].subscription_end_date;
            this.subscription.logs = formatPauseLogs(
              logs,
              this.sortArrayIntoObject(outstanding_services, "id")
            );
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setLogs(logs, outstanding_services) {
      const orders = this.sortArrayIntoObject(outstanding_services, "id");
      logs.forEach((log) => {
        let newLog = {
          action: log.action,
          service: log.service,
          start_date: log.start_date,
          end_date: log.end_date,
          orders: [],
        };
        log.order_ids.forEach((id) => {
          if (orders[id] && !orders[id].is_one_time_order) {
            newLog.orders.push(orders[id]);
          }
        });
        this.subscription.logs.push(newLog);
      });
      this.loading = false;
    },
    setOutstandingAmount(amount) {
      this.outstandingAmount = this.outstandingAmount + amount;
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  padding-top: 5px;
}

.pauses {
  max-height: 400px;
  overflow-y: scroll;

  .pauses-total {
    padding: 14px 20px 14px 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      color: #0f241b;

      &.total {
        font-weight: 600;
      }
    }
  }

  .el-collapse-item {
    border: none;
  }
}
</style>
