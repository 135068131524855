<template>
  <el-drawer
    :title="'Configure Next Subscription'"
    :visible.sync="shouldShow"
    direction="rtl"
    @close="closeEvent"
  >
    <div v-loading="prefilling">
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
          <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex">
              <el-col :span="24">
                <subscription-configuration
                  :config="subscription.plan"
                  @set-config="setPlan"
                  :menu-type-variation="menuTypeVariation"
                />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Subscription Amount" prop="amount">
                  <el-input v-model.number="form.pending_amount" v-number />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-align-center">
        <el-button type="primary" :loading="setting" @click="setNewSubscription"
          >Save</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import subscriptions from "@/requests/subscriptions/index";

export default {
  name: "SubscriptionNextForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      prefilling: false,
      setting: false,
      form: {
        plan_details: {},
        pending_amount: 0,
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.prefilling = true;
        const { plan, amount } = JSON.parse(JSON.stringify(this.subscription));
        setTimeout(() => {
          this.form.pending_plan = plan;
          this.form.pending_amount = amount;
          this.prefilling = false;
        }, 500);
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    setPlan({ config, amount }) {
      this.form.plan_details = config;
      this.form.pending_amount = amount;
    },
    formatForm() {
      const payload = {
        new_onboarding_flow: true,
        plan_details: this.form.plan_details,
        pending_amount: this.form.pending_amount,
      };

      const sortedPlan = this.sortObject(payload.plan_details);
      payload.plan_details = JSON.stringify(sortedPlan);

      return payload;
    },
    setNewSubscription() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const payload = this.formatForm();
        this.setting = true;

        subscriptions
          .setPendingPlan(this.subscription.id, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("updated", this.form);
              this.setting = false;
              this.closeEvent();
            } else {
              this.$message.error(response.data.message);
              this.setting = false;
            }
          })
          .catch((error) => {
            this.setting = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}
.eden-customer-service {
  margin-bottom: 40px;
}
</style>
