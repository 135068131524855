<template>
  <div>
    <eden-overview-card :title="'Prospect Subscription'">
      <template slot="action">
        <span
          class="font-base text-cursor text-primary"
          @click="createSubscription"
        >
          Activate
        </span>
      </template>
      <template slot="content">
        <subscription-service
          v-for="(value, key, index) in subscription.plan"
          :key="index"
          :service="key"
          :service-config="value"
          :show-count="false"
        />
      </template>
    </eden-overview-card>
    <subscription-form
      :show.sync="showSubscriptionForm"
      :action="'activate'"
      :customer="{
        name: customerName,
        email: customerEmail,
      }"
      :subscription="subscription"
      @updated="$emit('activated')"
    />
  </div>
</template>

<script>
import SubscriptionForm from "@/components/Subscriptions/Subscription/SubscriptionForm";
import SubscriptionService from "@/components/Subscriptions/Subscription/SubscriptionService";

export default {
  name: "CustomerProspectSubscription",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    customerName: {
      type: String,
      default: "",
    },
    customerEmail: {
      type: String,
      default: "",
    },
    subscription: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SubscriptionService,
    SubscriptionForm,
  },
  data() {
    return {
      showSubscriptionForm: false,
    };
  },
  methods: {
    createSubscription() {
      this.showSubscriptionForm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.amount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;

  span {
    font-weight: 600;
  }
}
</style>
