<template>
  <div>
    <el-dialog
      :title="`${actionLabel[action]} Preference`"
      :visible.sync="showPreferenceForm"
      @close="close"
      width="30%"
      append-to-body
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-form-item label="Note" prop="preference" :rules="validateField()">
          <el-input type="textarea" :rows="3" v-model="form.preference" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="close">Cancel</el-button>
        <el-button
          type="primary"
          :loading="updating"
          @click="setAction"
          :disabled="!form.preference"
          >{{ action === "add" ? "Add" : "Save" }} Preference</el-button
        >
      </span>
    </el-dialog>
    <eden-confirm-dialog
      :title="'Delete Preference'"
      :button-text="'Delete'"
      :button-type="'danger'"
      :button-status.sync="deleting"
      :show.sync="showConfirm"
      @confirm="deletePreference"
      @close="close"
    >
      <p>
        This action cannot be undone. Are you sure you want to delete this
        preference?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import preferences from "@/requests/customers/customer/preferences";

export default {
  name: "CustomerPreferenceAction",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "",
    },
    preference: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        preference: "",
      },
      updating: false,
      showPreferenceForm: false,
      showConfirm: false,
      deleting: false,
    };
  },
  computed: {
    customerId() {
      return parseInt(this.$route.params.id);
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    actionLabel() {
      return {
        add: "Add",
        edit: "Edit",
      };
    },
  },
  watch: {
    show() {
      if (this.show) {
        switch (this.action) {
          case "add":
            this.showPreferenceForm = true;
            break;
          case "edit":
            this.form.preference = this.preference.preference;
            this.showPreferenceForm = true;
            break;
          case "delete":
            this.showConfirm = true;
        }
      }
    },
  },
  methods: {
    close() {
      this.$refs.form.resetFields();
      this.setShow = false;
      this.showPreferenceForm = false;
      this.showConfirm = false;
    },
    setAction() {
      this.action === "add" ? this.add() : this.update();
    },
    add() {
      const payload = {
        preference: this.form.preference,
        user_id: this.preference.user_id,
        service: this.preference.service,
      };
      this.updating = true;

      preferences
        .add(payload)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            this.$emit("action");
            this.close();
          }
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    update() {
      const payload = {
        preference: this.form.preference,
        user_id: this.preference.user_id,
        service: this.preference.service,
      };
      this.updating = true;

      preferences
        .update(this.preference.preference_id, payload)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            this.$emit("action");
            this.close();
          }
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    deletePreference() {
      const payload = {
        preference_id: this.preference.preference_id,
      };
      this.updating = true;
      preferences
        .delete(this.customerId, payload)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            this.$emit("action");
            this.close();
          }
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
