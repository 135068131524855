export const CLEANING_ACTIVITIES = (order) => {
  const extra = order.extra_details;
  return {
    cleaner_assigned: {
      label: "Cleaner assigned",
      status: !!extra.is_cleaner_assigned,
      time: extra.cleaner_assigned_time,
    },
    cleaner_enroute: {
      label: "Cleaner en route",
      status: !!extra.is_cleaner_enroute,
      time: extra.cleaner_enroute_time,
    },
    cleaner_arrived: {
      label: "Cleaner arrived",
      status: !!extra.is_cleaner_arrived,
      time: extra.cleaner_arrival_time,
    },
    cleaner_started: {
      label: "Cleaning started",
      status: !!extra.is_cleaning_started,
      time: extra.cleaning_started_time,
    },
    cleaner_done: {
      label: "Cleaning done",
      status: !!extra.is_cleaning_started,
      time: extra.is_cleaning_done,
    },
  };
};
