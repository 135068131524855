<template>
  <div>
    <div class="el-drawer--content">
      <div class="el-drawer--content__body mb-3">
        <el-form :model="form" label-position="top" ref="oneTimeOrder">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="What type of order?">
                <el-radio-group
                  v-model="form.complementary"
                  class="eden-custom--radio flex"
                >
                  <el-radio :label="0">Paid</el-radio>
                  <el-radio :label="1">Complimentary</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Services">
                <el-checkbox-group
                  v-model="form.services"
                  class="eden-custom--radio flex"
                >
                  <el-checkbox
                    v-for="(service, index) in services"
                    :key="index"
                    :label="service.service_name.toLowerCase()"
                    @change="
                      resetConfig($event, service.service_name.toLowerCase())
                    "
                    >{{ service.service_name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="fadeIn">
            <template v-for="service in form.services">
              <component
                :is="`${service}-configuration`"
                :key="service"
                :config="form.plan[service]"
                :one-time="true"
                @update="updateConfig"
                :menu-type-variation="menuTypeVariation"
              />
              <template v-if="form.complementary && service === 'meal'">
                <el-form-item
                  :key="service"
                  label="Reason for Complementary Meal"
                  prop="reason"
                  class="my-1"
                >
                  <el-select v-model="form.reason" placeholder="Select reason">
                    <el-option
                      v-for="(reason, key) in complementaryReasons"
                      :key="key"
                      :label="reason"
                      :value="reason"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </template>
          </div>
        </el-form>
      </div>
      <div class="el-drawer--footer">
        <el-button type="primary" :disabled="disableNext" @click="next"
          >Next</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomerOneTimeOrderForm",
  props: {
    formResponses: {
      type: Object,
      default() {
        return {};
      },
    },
    customerId: {
      type: [String, Number],
      default: "",
    },
    menuTypeVariation: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        complementary: 0,
        reason: "",
        user_id: "",
        services: [],
        plan: {},
        start_date: "",
      },
      complementaryReasons: [
        "Incomplete order delivery",
        "Late delivery (Spoilt or rejected orders)",
        "Unavailable to receive order",
        "Wrong order delivery",
        "Order not delivered",
        "A Gift from Eden",
      ],
    };
  },
  computed: {
    setFormResponses: {
      get() {
        return this.formResponses;
      },
      set(value) {
        this.$emit("update:formResponses", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
    disableNext() {
      return !this.form.services.length;
    },
  },
  created() {
    if (this.formResponses.services?.length) {
      this.form = { ...this.formResponses };
    }
  },
  methods: {
    resetConfig(status, service) {
      status ? (this.form.plan[service] = {}) : delete this.form.plan[service];
    },
    updateConfig({ response, service }) {
      this.form.plan[service] = { ...response };
    },
    next() {
      this.setFormResponses = { ...this.form };
      this.$emit("summarise");
    },
  },
};
</script>

<style lang="scss" scoped>
.service-form {
  margin-bottom: 50px;

  .heading {
    color: #21312a;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
</style>
