export const BEAUTY_ITEMS = () => {
  return [
    "first_beauty",
    "second_beauty",
    "extras_beauty",
    "extras_two_beauty",
    "extras_three_beauty",
    "extras_four_beauty",
  ];
};

export const BEAUTY_ITEM_ADDONS = () => {
  return ["one", "two", "three", "four", "five"];
};

export const BEAUTY_ACTIVITIES = (order) => {
  // const extra = order.extra_details || {};
  return {
    sp_assigned: {
      label: "Beautician assigned",
      status: !!order.is_beautician_assigned,
      time: order.beautician_assigned_time,
    },
    sp_enroute: {
      label: "En route",
      status: !!order.is_beautician_enroute,
      time: order.beautician_enroute_time,
    },
    sp_arrived: {
      label: "Arrived",
      status: !!order.is_beautician_arrived,
      time: order.beautician_arrived_time,
    },
    service_started: {
      label: "Service started",
      status: !!order.is_service_started,
      time: order.service_started_time,
    },
    completed: {
      label: "Completed",
      status: !!order.is_service_completed,
      time: order.service_completed_time,
    },
  };
};
